import { AlertIcon } from 'assets/icons';
import Breadcrumb from 'components/Breadcrumb';
import TitlePage from 'components/TitlePage';
import { CircleSpin } from 'components/UiComponents';
import Modal from 'components/UiComponents/Modal';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { BiNotification } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { DelogsService } from 'services/delogs.service';
import { DEResType } from 'types/data.type';
import { PermissionsGate } from 'components/PermissionsGate';
import { PERMISSIONS_KEY } from 'constant';
import { PERMISSIONS_ACTIONS } from 'hooks';

const text_style = 'text-sm font-semibold leading-5';

const DEManagement = () => {
  const navigate = useNavigate();
  const [crumbs] = useState([
    {
      name: 'Quản Lý Day-End',
      url: '/de-management'
    }
  ]);
  const [confirmStartDEModal, setConfirmStartDEModal] = useState(false);
  const [confirmFinishDEModal, setConfirmFinishDEModal] = useState(false);
  const [confirmRestartApiG2G3Modal, setConfirmRestartApiG2G3Modal] =
    useState(false);
  const [confirmRestartOrderPushModal, setConfirmRestartOrderPushModal] =
    useState(false);
  const [restartApiG2G3Loading, setRestartApiG2G3Loading] = useState(false);
  const [restartOrderPushLoading, setRestartOrderPushLoading] = useState(false);
  const [submitStartDE, setSubmitStartDE] = useState(false);
  const [submitFinishDE, setSubmitFinishDE] = useState(false);
  const [deList, setDEList] = useState<DEResType>();
  const [reloadPage, setReloadPage] = useState<boolean>(true);

  const selected = (url: any) => {
    if (url) {
      navigate(url);
    } else {
      navigate(-1);
    }
  };

  const getListDE = async () => {
    const res = await DelogsService.getDELogs();
    if (res) {
      setDEList(res);
    }
  };

  const handleStartDE = async () => {
    setSubmitStartDE(true);
    const res = await DelogsService.startDE();
    if (res?.status === 'Success') {
      setReloadPage(!reloadPage);
    } else {
      toast.error('Bắt đầu DE thất bại!', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      });
    }
    setSubmitStartDE(false);
    setConfirmStartDEModal(false);
  };

  const handleFinishDE = async () => {
    setSubmitFinishDE(true);
    const res = await DelogsService.endDE();
    if (res?.status === 'Success') {
      setReloadPage(!reloadPage);
    } else {
      toast.error('Hoàn thành DE thất bại!', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      });
    }
    setSubmitFinishDE(false);
    setConfirmFinishDEModal(false);
  };

  const formatTime = (time: any) => {
    const hour = Math.floor(time / 3600);
    const minute = Math.floor((time % 3600) / 60);
    return `${hour < 10 ? `0${hour}` : hour}:${
      minute < 10 ? `0${minute}` : minute
    }`;
  };

  useEffect(() => {
    const timer = setInterval(() => {
      getListDE();
    }, 60000);

    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    getListDE();
  }, [reloadPage]);

  const handleRestartApiG2G3 = async () => {
    setRestartApiG2G3Loading(true);
    const res = await DelogsService.restartApiG2G3();
    if (res?.status === 'Success') {
      toast.success('Restart API G2/G3 thành công!', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      });
    } else {
      toast.error('Restart API G2/G3 thất bại!', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      });
    }
    setRestartApiG2G3Loading(false);
    setConfirmRestartApiG2G3Modal(false);
  };

  const handleRestartOrderPush = async () => {
    setRestartOrderPushLoading(true);
    const res = await DelogsService.restartOrderPush();
    if (res?.status === 'Success') {
      toast.success('Restart OrderPush thành công!', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      });
    } else {
      toast.error('Restart OrderPush thất bại!', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      });
    }
    setConfirmRestartOrderPushModal(false);
    setRestartOrderPushLoading(false);
  };

  return (
    <>
      <Breadcrumb crumbs={crumbs} selected={selected} />
      <div className="flex items-center">
        <TitlePage icon={() => <BiNotification />} name="Quản Lý Day-End" />
      </div>

      <PermissionsGate
        name={PERMISSIONS_KEY.ManageDE}
        action={PERMISSIONS_ACTIONS.UPDATE}
      >
        {deList && (
          <div
            className={`mt-[42px] flex flex-col items-center gap-[10px] px-4 py-5 h-[250px] ${
              deList?.de_processing ? 'bg-red-light' : 'bg-white'
            }`}
          >
            <div
              className={`w-[160px] h-[160px] rounded-full flex justify-center items-center cursor-pointer hover:opacity-90 ${
                deList?.de_processing ? 'bg-red-dark' : 'bg-0BA5EC'
              }`}
              onClick={() => {
                deList?.de_processing
                  ? setConfirmFinishDEModal(true)
                  : setConfirmStartDEModal(true);
              }}
            >
              <p className="text-base font-semibold leading-[22px] text-white">
                {deList?.de_processing ? 'Hoàn thành DE' : 'Bắt đầu DE'}
              </p>
            </div>
            {deList?.de_processing && (
              <p className="text-sm font-semibold leading-5 text-101828">
                Thời gian trôi qua{' '}
                <span className="text-red-F04438 ml-[2px]">
                  {formatTime(deList?.elapsed_seconds)}
                </span>
              </p>
            )}
          </div>
        )}
        <div className="flex p-10 gap-10 items-center justify-center">
          <div
            className={`rounded-[24px] py-5 px-10 flex justify-center items-center cursor-pointer hover:opacity-90 bg-0BA5EC`}
            onClick={() => setConfirmRestartApiG2G3Modal(true)}
          >
            <span className="text-base font-semibold leading-[22px] text-white">
              Restart API G2/G3
            </span>
          </div>
          <div
            className={`rounded-[24px] py-5 px-10 flex justify-center items-center cursor-pointer hover:opacity-90 bg-0BA5EC`}
            onClick={() => setConfirmRestartOrderPushModal(true)}
          >
            <span className="text-base font-semibold leading-[22px] text-white">
              Restart OrderPush
            </span>
          </div>
        </div>
      </PermissionsGate>
      <div>
        <p className="text-base font-semibold leading-[22px]">Lịch sử</p>
        <div className="w-full h-auto relative overflow-auto rounded shadow mt-4">
          <table className="table w-full rounded-lg">
            <thead className="bg-gray-f2 border-b-2 border-gray-200">
              <tr>
                <th>
                  <span>Ngày</span>
                </th>
                <th>
                  <span>Giờ</span>
                </th>
                <th>
                  <span>Lệnh</span>
                </th>
                <th>
                  <span>User</span>
                </th>
              </tr>
            </thead>
            <tbody>
              {deList?.logs?.map(item => (
                <tr key={item?.id + Math.random()}>
                  <td className="w-[25%]">
                    <p className={text_style}>
                      {moment(item?.time).format('DD/MM/YYYY') || '-'}
                    </p>
                  </td>
                  <td className="w-[25%]">
                    <p className={text_style}>
                      {moment(item?.time).format('HH:mm') || '-'}
                    </p>
                  </td>
                  <td className="w-[25%]">
                    <p className={text_style}>{item?.action || '-'}</p>
                  </td>
                  <td>
                    <p className={text_style}>{item?.username || '-'}</p>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {deList?.logs?.length === 0 && (
          <p className="text-center text-sm mt-3">No announcement found</p>
        )}
      </div>
      <Modal
        show={confirmStartDEModal}
        title={''}
        onClose={() => {
          setConfirmStartDEModal(false);
        }}
        className="max-w-[640px]"
      >
        <div>
          <div className="w-12 h-12 rounded border border-red-F04438 flex justify-center items-center">
            <AlertIcon />
          </div>
          <div className="mt-4 flex flex-col gap-[26px]">
            <p className="text-lg font-semibold leading-6">
              Bắt đầu quá trình Day-End cho SBBS?
            </p>
            <p className="text-sm font-normal leading-5">
              Bạn chỉ nên bắt đầu quá trình Day-End khi hệ thống AFE cũng chuẩn
              bị bắt đầu Day-End. Khi bắt đầu quá trình Day-End, ứng dụng iTrade
              Mobile App sẽ gửi thông báo cho Khách hàng và các Khách hàng sẽ bị
              hạn chế một số tính năng trên ứng dụng và web-trading.
              <br />
              <br />
              Bạn có chắc chắn muốn bắt đầu quá trình Day-End?
            </p>
          </div>
        </div>
        <div className="flex justify-between mt-[56px]">
          <button
            type="button"
            className="flex justify-center w-[calc(50%-6px)] form-button-seconday"
            onClick={() => {
              setConfirmStartDEModal(false);
            }}
          >
            <p className="text-base font-semibold leading-[22px]">Huỷ</p>
          </button>
          <button
            type="submit"
            className="flex justify-center w-[calc(50%-6px)] form-button"
            disabled={submitStartDE}
            onClick={() => {
              handleStartDE();
            }}
          >
            {submitStartDE && <CircleSpin />}
            <p className="text-base font-semibold leading-[22px]">
              Bắt đầu Day-End
            </p>
          </button>
        </div>
      </Modal>
      <Modal
        show={confirmFinishDEModal}
        title={''}
        onClose={() => {
          setConfirmFinishDEModal(false);
        }}
        className="max-w-[640px]"
      >
        <div>
          <div className="w-12 h-12 rounded border border-red-F04438 flex justify-center items-center">
            <AlertIcon />
          </div>
          <div className="mt-4 flex flex-col gap-[26px]">
            <p className="text-lg font-semibold leading-6">
              Hoàn thành quá trình Day-End cho SBBS?
            </p>
            <p className="text-sm font-normal leading-5">
              Bạn chỉ nên hoàn thành quá trình Day-End khi hệ thống AFE đã xong
              quá trình Day-End. Khi hoàn thành quá trình Day-End, ứng dụng
              iTrade Mobile App sẽ gửi thông báo cho Khách hàng và các Khách
              hàng sẽ sử dụng lại tính năng trên ứng dụng và web-trading.
              <br />
              <br />
              Bạn có chắc chắn muốn hoàn thành quá trình Day-End?
            </p>
          </div>
        </div>
        <div className="flex justify-between mt-[56px]">
          <button
            type="button"
            className="flex justify-center w-[calc(50%-6px)] form-button-seconday"
            onClick={() => {
              setConfirmFinishDEModal(false);
            }}
          >
            <p className="text-base font-semibold leading-[22px]">Huỷ</p>
          </button>
          <button
            type="submit"
            className="flex justify-center w-[calc(50%-6px)] form-button"
            disabled={submitFinishDE}
            onClick={() => {
              handleFinishDE();
            }}
          >
            {submitFinishDE && <CircleSpin />}
            <p className="text-base font-semibold leading-[22px]">
              Hoàn thành Day-End
            </p>
          </button>
        </div>
      </Modal>
      <Modal
        show={confirmRestartApiG2G3Modal}
        title={''}
        onClose={() => {
          setConfirmRestartApiG2G3Modal(false);
        }}
        className="max-w-[640px]"
      >
        <div>
          <div className="w-12 h-12 rounded border border-red-F04438 flex justify-center items-center">
            <AlertIcon />
          </div>
          <div className="mt-4 flex flex-col gap-[26px]">
            <p className="text-lg font-semibold leading-6">
              Bạn có chắc chắn muốn restart API G2/G3?
            </p>
          </div>
        </div>
        <div className="flex justify-between mt-[56px]">
          <button
            type="button"
            className="flex justify-center w-[calc(50%-6px)] form-button-seconday"
            onClick={() => {
              setConfirmRestartApiG2G3Modal(false);
            }}
          >
            <p className="text-base font-semibold leading-[22px]">Huỷ</p>
          </button>
          <button
            type="submit"
            className="flex justify-center w-[calc(50%-6px)] form-button"
            disabled={restartApiG2G3Loading}
            onClick={() => {
              handleRestartApiG2G3();
            }}
          >
            {restartApiG2G3Loading && <CircleSpin />}
            <p className="text-base font-semibold leading-[22px]">
              Restart API G2/G3
            </p>
          </button>
        </div>
      </Modal>
      <Modal
        show={confirmRestartOrderPushModal}
        title={''}
        onClose={() => {
          setConfirmRestartOrderPushModal(false);
        }}
        className="max-w-[640px]"
      >
        <div>
          <div className="w-12 h-12 rounded border border-red-F04438 flex justify-center items-center">
            <AlertIcon />
          </div>
          <div className="mt-4 flex flex-col gap-[26px]">
            <p className="text-lg font-semibold leading-6">
              Bạn có chắc chắn muốn restart OrderPush?
            </p>
          </div>
        </div>
        <div className="flex justify-between mt-[56px]">
          <button
            type="button"
            className="flex justify-center w-[calc(50%-6px)] form-button-seconday"
            onClick={() => {
              setConfirmRestartOrderPushModal(false);
            }}
          >
            <p className="text-base font-semibold leading-[22px]">Huỷ</p>
          </button>
          <button
            type="submit"
            className="flex justify-center w-[calc(50%-6px)] form-button"
            disabled={restartOrderPushLoading}
            onClick={() => {
              handleRestartOrderPush();
            }}
          >
            {restartOrderPushLoading && <CircleSpin />}
            <p className="text-base font-semibold leading-[22px]">
              Restart OrderPush
            </p>
          </button>
        </div>
      </Modal>
    </>
  );
};

export default DEManagement;
