import Breadcrumb from 'components/Breadcrumb';
import { PermissionsGate } from 'components/PermissionsGate';
import TitlePage from 'components/TitlePage';
import { CircleSpin } from 'components/UiComponents';
import Modal from 'components/UiComponents/Modal';
import { PERMISSIONS_KEY } from 'constant';
import { FORM_FORMAT } from 'constant/form.constant';
import { PERMISSIONS_ACTIONS } from 'hooks';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { AiOutlineBook, AiOutlineDelete, AiOutlineEdit } from 'react-icons/ai';
import { Link, useNavigate } from 'react-router-dom';
import Switch from 'react-switch';
import { toast } from 'react-toastify';
import { faqService } from 'services/faq.service';

const FaqCategoryManagement = () => {
  const navigate = useNavigate();
  const [confirmDeleteSecondaryModal, setConfirmDeleteSecondaryModal] =
    useState(false);
  const [submitDeleteSecondary, setSubmitDeleteSecondary] = useState(false);
  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);
  const [submitDelete, setSubmitDelete] = useState(false);
  const [idCategory, setIdCategory] = useState('');
  const [primaryCategories, setPrimaryCategories] = useState<any>();
  const [secondaryCategories, setSecondaryCategories] = useState<any>();
  const [reload, setReload] = useState(false);
  const [idForShowSecondary, setIdForShowSecondary] = useState();

  const [crumbs] = useState([
    {
      name: 'Quản Lý Category',
      url: '/faq-category-management'
    }
  ]);

  const selected = (url: any) => {
    if (url) {
      navigate(url);
    } else {
      navigate(-1);
    }
  };

  const getCategoryList = async (id?: string) => {
    const param = {
      CategoryId: id,
      IsHaveFaq: null
    };

    const res = await faqService.getFaqCategoryList(param);
    if (res?.status === 'Success') {
      if (id) {
        setSecondaryCategories(res?.data);
      } else {
        setPrimaryCategories(res?.data);
      }
    } else {
      toast.error('Không thể lấy danh sách category!', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      });
    }
  };

  const handleStatusChange = async (
    nextChecked: any,
    id: any,
    isPrimary: boolean
  ) => {
    const param = {
      is_active: nextChecked
    };
    const res = await faqService.changeStatusFaqCategory(id, param);

    if (res?.status === 'Success') {
      toast.success('Thay đổi trạng thái thành công!', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      });
      if (isPrimary) {
        getCategoryList();
      } else {
        setReload(!reload);
      }
    } else {
      toast.error('Thay đổi trạng thái thất bại!', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      });
    }
  };

  const handleDeleteCategory = async (id: any, isPrimary: boolean) => {
    setSubmitDelete(true);
    setSubmitDeleteSecondary(true);
    const res = await faqService.deleteFaqCategory(id);
    if (res?.status === 'Success') {
      toast.success('Xóa category thành công!', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      });

      if (isPrimary) {
        getCategoryList();
      } else {
        setReload(!reload);
      }
    } else {
      toast.error('Xóa category thất bại!', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      });
    }
    setSubmitDelete(false);
    setConfirmDeleteModal(false);
    setSubmitDeleteSecondary(false);
    setConfirmDeleteSecondaryModal(false);
  };

  useEffect(() => {
    getCategoryList(idForShowSecondary);
  }, [reload, idForShowSecondary]);

  return (
    <>
      <Breadcrumb crumbs={crumbs} selected={selected} />
      <div className="flex justify-between items-center">
        <TitlePage icon={() => <AiOutlineBook />} name="Quản Lý Category" />
        <PermissionsGate
          name={PERMISSIONS_KEY.ManageFaqCategory}
          action={PERMISSIONS_ACTIONS.CREATE}
        >
          <Link to={'/faq-category-management/add'}>
            <button
              type="button"
              className="flex justify-center w-full md:w-52 form-button"
            >
              Tạo mới category
            </button>
          </Link>
        </PermissionsGate>
      </div>
      <div className="mt-10 flex gap-[61px]">
        <div className="w-1/2">
          <p className="text-101828 text-base font-semibold leading-[22px]">
            Primary Categories
          </p>
          <div className="h-auto relative overflow-auto rounded shadow mt-4">
            <table className="table table-category w-full rounded-lg">
              <thead className="bg-gray-f2 border-b-2 border-gray-200">
                <tr>
                  <th style={{ width: '60px' }}>STT</th>
                  <th>
                    <span>Tên category</span>
                  </th>
                  <th>
                    <span>Ngày tạo</span>
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {primaryCategories?.length > 0 ? (
                  primaryCategories?.map((item: any, index: any) => (
                    <tr
                      key={item?.id}
                      onClick={() => {
                        setIdForShowSecondary(item?.id);
                      }}
                      className={
                        idForShowSecondary === item?.id ? 'row-active' : ''
                      }
                    >
                      <td>{index + 1}</td>
                      <td className="font-semibold max-w-[150px] overflow-scroll hidden-scrollbar">
                        <div className="w-[300px]">
                          <span>
                            {item?.category_name ? item?.category_name : '-'}
                          </span>
                        </div>
                      </td>
                      <td>
                        <span>
                          {item?.created_date
                            ? moment(new Date(item.created_date)).format(
                                FORM_FORMAT.TABLE_DATEFULLYEAR
                              )
                            : '-'}
                        </span>
                      </td>
                      <td>
                        <div className="flex justify-center gap-3">
                          <Switch
                            onChange={(value: any, event) => {
                              event.stopPropagation();
                              handleStatusChange(value, item?.id, true);
                            }}
                            checked={item?.is_active}
                            className="react-switch"
                            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                            height={18}
                            width={33}
                            handleDiameter={14}
                            checkedIcon={false}
                            uncheckedIcon={false}
                            onColor={'#61CB2F'}
                          />
                          <PermissionsGate
                            name={PERMISSIONS_KEY.ManageFaqCategory}
                            action={PERMISSIONS_ACTIONS.UPDATE}
                          >
                            <div className="items-center text-base table-action-btn">
                              <Link to={`/faq-category-management/${item?.id}`}>
                                <AiOutlineEdit className="text-0BA5EC text-lg" />
                              </Link>
                            </div>
                          </PermissionsGate>
                          <PermissionsGate
                            name={PERMISSIONS_KEY.ManageFaqCategory}
                            action={PERMISSIONS_ACTIONS.DELETE}
                          >
                            <div className="flex gap-3 items-center text-base table-action-btn">
                              <div
                                onClick={e => {
                                  setIdCategory(item?.id);
                                  setConfirmDeleteModal(true);
                                  e.stopPropagation();
                                }}
                              >
                                <AiOutlineDelete className="text-red-3b text-lg" />
                              </div>
                            </div>
                          </PermissionsGate>
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
        <div className="w-1/2">
          <p className="text-101828 text-base font-semibold leading-[22px]">
            Second Categories
          </p>
          <div className="h-auto relative mt-4 overflow-auto rounded shadow">
            <table className="table w-full rounded-lg">
              <thead className="bg-gray-f2 border-b-2 border-gray-200">
                <tr>
                  <th style={{ width: '60px' }}>STT</th>
                  <th>
                    <span>Tên category</span>
                  </th>
                  <th>
                    <span>Ngày tạo</span>
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {secondaryCategories?.length > 0 ? (
                  secondaryCategories?.map((item: any, index: any) => (
                    <tr key={item?.id}>
                      <td>{index + 1}</td>
                      <td className="font-semibold max-w-[150px] overflow-scroll hidden-scrollbar">
                        <div className="w-[300px]">
                          <span>
                            {item?.category_name ? item?.category_name : '-'}
                          </span>
                        </div>
                      </td>
                      <td>
                        {item?.created_date
                          ? moment(new Date(item.created_date)).format(
                              FORM_FORMAT.TABLE_DATEFULLYEAR
                            )
                          : '-'}
                      </td>
                      <td>
                        <div className="flex justify-center gap-3">
                          <Switch
                            onChange={(value: any, event) => {
                              event.stopPropagation();
                              handleStatusChange(value, item?.id, false);
                            }}
                            checked={item?.is_active}
                            className="react-switch"
                            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                            height={18}
                            width={33}
                            handleDiameter={14}
                            checkedIcon={false}
                            uncheckedIcon={false}
                            onColor={'#61CB2F'}
                          />
                          <PermissionsGate
                            name={PERMISSIONS_KEY.ManageFaqCategory}
                            action={PERMISSIONS_ACTIONS.UPDATE}
                          >
                            <div className="items-center text-base table-action-btn">
                              <Link to={`/faq-category-management/${item?.id}`}>
                                <AiOutlineEdit className="text-0BA5EC text-lg" />
                              </Link>
                            </div>
                          </PermissionsGate>
                          <PermissionsGate
                            name={PERMISSIONS_KEY.ManageFaqCategory}
                            action={PERMISSIONS_ACTIONS.DELETE}
                          >
                            <div className="flex gap-3 items-center text-base table-action-btn">
                              <div
                                onClick={() => {
                                  setIdCategory(item?.id);
                                  setConfirmDeleteSecondaryModal(true);
                                }}
                              >
                                <AiOutlineDelete className="text-red-3b text-lg" />
                              </div>
                            </div>
                          </PermissionsGate>
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <Modal
        show={confirmDeleteModal}
        title={'Xác Nhận Xoá'}
        onClose={() => {
          setConfirmDeleteModal(false);
        }}
        className="max-w-[420px]"
      >
        <div className="flex space-x-4">
          <button
            type="button"
            className="flex justify-center w-full md:w-52 form-button-seconday"
            onClick={() => {
              setConfirmDeleteModal(false);
            }}
          >
            Huỷ
          </button>
          <button
            type="submit"
            className="flex justify-center w-full md:w-52 form-button"
            disabled={submitDelete}
            onClick={() => {
              handleDeleteCategory(idCategory, true);
            }}
          >
            {submitDelete && <CircleSpin />}
            Đồng ý
          </button>
        </div>
      </Modal>

      <Modal
        show={confirmDeleteSecondaryModal}
        title={'Xác Nhận Xoá'}
        onClose={() => {
          setConfirmDeleteSecondaryModal(false);
        }}
        className="max-w-[420px]"
      >
        <div className="flex space-x-4">
          <button
            type="button"
            className="flex justify-center w-full md:w-52 form-button-seconday"
            onClick={() => {
              setConfirmDeleteSecondaryModal(false);
            }}
          >
            Huỷ
          </button>
          <button
            type="submit"
            className="flex justify-center w-full md:w-52 form-button"
            disabled={submitDeleteSecondary}
            onClick={() => {
              handleDeleteCategory(idCategory, false);
            }}
          >
            {submitDelete && <CircleSpin />}
            Đồng ý
          </button>
        </div>
      </Modal>
    </>
  );
};

export default FaqCategoryManagement;
