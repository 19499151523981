import React, { useEffect, useState, useCallback } from 'react';
import { ArticleCard } from 'components/ArticleCard';
import Breadcrumb from 'components/Breadcrumb';
import TitlePage from 'components/TitlePage';
import { CircleSpin } from 'components/UiComponents';
import Modal from 'components/UiComponents/Modal';
import { SubmitHandler, useForm } from 'react-hook-form';
import { AiOutlineBook } from 'react-icons/ai';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { addNews, getNewsDetail, updateNews } from 'services/news.service';
import EditorByJodit from 'components/Editor/EditorByJodit';

const NewsAdd = () => {
  const {
    register,
    handleSubmit,
    setValue,
    trigger,
    formState: { errors }
  } = useForm<any>({});
  const paramsURL = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const state = location?.state as { data: any };
  const paramsId = paramsURL?.id;
  const bannerDataState = state?.data;
  const [loading, setLoading] = useState(false);
  const [confirmAddModal, setConfirmAddModal] = useState(false);
  const [confirmUpdateModal, setConfirmUpdateModal] = useState(false);
  const [submitAdd, setSubmitAdd] = useState(false);
  const [submitUpdate, setSubmitUpdate] = useState(false);
  const [editorState, setEditorState] = useState<any>();
  const [editorEngState, setEditorEngState] = useState<any>();

  const selected = (url: any) => {
    if (url) {
      navigate(url);
    } else {
      navigate(-1);
    }
  };

  const crumbs = [
    {
      name: 'Quản Lý Tin Tức Sự Kiện',
      url: '/news-management'
    },
    {
      name: paramsId ? 'Chỉnh sửa Tin Tức Sự Kiện' : 'Tạo mới Tin Tức Sự Kiện'
    }
  ];

  const getDetai = async (id: string) => {
    setLoading(true);
    try {
      const res = await getNewsDetail(id);
      if (res.data) {
        setValue('title', res.data?.title);
        setValue('title_eng', res.data?.title_eng);
        setValue('slug', res.data?.slug);
        setValue('information', res.data?.information);
        setValue('information_eng', res.data?.information_eng);
        setEditorState(res.data?.information);
        setEditorEngState(res.data?.information_eng);
      }
    } catch (error: any) {}
    setLoading(false);
  };

  const submitAddHandler: SubmitHandler<any> = async values => {
    setSubmitAdd(true);
    const params = {
      title: values?.title,
      title_eng: values?.title_eng,
      slug: values?.slug,
      information: editorState,
      information_eng: editorEngState,
      align_type: 'Left'
    };

    const res = await addNews(params);
    if (res?.status === 'Success') {
      const data = res?.data;
      toast.success('Tạo tin tức thành công!', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      });
      setSubmitAdd(false);
      setConfirmAddModal(false);
      navigate(`/news-management/edit/${data.id}`, {
        state: { data }
      });
    } else {
      toast.error(res?.error_message || 'Có lỗi, vui lòng kiểm tra lại!', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      });
      setSubmitAdd(false);
      setConfirmAddModal(false);
    }
  };

  const submitUpdateHandler: SubmitHandler<any> = async values => {
    setSubmitUpdate(true);
    const params = {
      title: values?.title,
      title_eng: values?.title_eng,
      slug: values?.slug,
      information: editorState,
      information_eng: editorEngState,
      align_type: 'Left'
    };
    const res = await updateNews(paramsId || '', params);
    if (res?.status === 'Success') {
      const data = res?.data;
      toast.success('Chỉnh sửa tin tức thành công!', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      });
      setSubmitUpdate(false);
      setConfirmUpdateModal(false);
      navigate(`/news-management/edit/${data.id}`, {
        state: { data }
      });
    } else {
      toast.error(res?.error_message || 'Có lỗi, vui lòng kiểm tra lại!', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      });
      setSubmitUpdate(false);
      setConfirmUpdateModal(false);
    }
  };

  const onError = (errors: any, e: any) => {
    if (errors) {
      setSubmitAdd(false);
      setConfirmAddModal(false);
      setSubmitUpdate(false);
      setConfirmUpdateModal(false);
    }
  };

  useEffect(() => {
    if (paramsId && !bannerDataState) {
      getDetai(paramsId);
    }
  }, [paramsId, bannerDataState]);

  const onEditorStateChange = useCallback((value: string) => {
    setEditorState(value);
    setValue('information', value);
  }, []);

  const onEditorEngStateChange = useCallback((value: string) => {
    setEditorEngState(value);
    setValue('information_eng', value);
  }, []);

  return (
    <>
      {loading ? (
        <>
          <div className="flex justify-center mt-4 items-center tex-sm">
            <CircleSpin color="text-primary-e2" /> Loading...
          </div>
        </>
      ) : (
        <>
          <Breadcrumb crumbs={crumbs} selected={selected} />
          <TitlePage
            icon={() => <AiOutlineBook />}
            name={`${paramsId ? 'Chỉnh sửa' : 'Tạo mới'} Tin Tức Sự Kiện`}
          />
          <div className="mt-10">
            <form
              className="form"
              onSubmit={handleSubmit(submitAddHandler, onError)}
              noValidate
              autoComplete="off"
            >
              <div className="flex gap-3">
                <ArticleCard className="w-full">
                  <div className="form-group">
                    <label htmlFor="title">
                      <span className=" text-red-3b mr-[2px]">*</span>
                      Tiêu đề
                    </label>
                    <input
                      id="title"
                      type="text"
                      autoComplete="title"
                      placeholder="Nhập tiêu đề"
                      className="form-control"
                      {...register('title', {
                        required: 'Vui lòng nhập tiêu đề',
                        setValueAs: (value: string) => value?.trim(),
                        onChange: () => {
                          trigger('title');
                        }
                      })}
                    />
                    <span className="text-red-3b text-xs">
                      {errors?.title?.message as string}
                    </span>
                  </div>
                  <div className="form-group">
                    <label htmlFor="title_eng" className="required-before">
                      Tiêu đề (EN)
                    </label>
                    <input
                      id="title_eng"
                      type="text"
                      autoComplete="title_eng"
                      placeholder="Nhập tiêu đề (EN)"
                      className="form-control"
                      {...register('title_eng', {
                        required: 'Vui lòng nhập tiêu đề (EN)',
                        setValueAs: (value: string) => value?.trim(),
                        onChange: () => {
                          trigger('title_eng');
                        }
                      })}
                    />
                    <span className="text-red-3b text-xs">
                      {errors?.title_eng?.message as string}
                    </span>
                  </div>
                  <div className="form-group">
                    <label htmlFor="slug" className="required-before">
                      Slug
                    </label>
                    <input
                      id="slug"
                      type="text"
                      autoComplete="slug"
                      placeholder="Nhập slug"
                      className="form-control"
                      {...register('slug', {
                        required: 'Vui lòng nhập slug',
                        setValueAs: (value: string) => value?.trim(),
                        onChange: () => {
                          trigger('slug');
                        }
                      })}
                    />
                    <span className="text-red-3b text-xs">
                      {errors?.slug?.message as string}
                    </span>
                  </div>
                  <div className="form-group">
                    <label htmlFor="information" className="required-before">
                      Nội dung
                    </label>
                    <div className="w-full min-h-[300px]">
                      <EditorByJodit
                        editorValue={editorState}
                        saveEditorStateValues={onEditorStateChange}
                      />
                      <input
                        id="information"
                        type="hidden"
                        autoComplete="information"
                        className="form-control"
                        {...register('information', {
                          required: 'Vui lòng nhập nội dung'
                        })}
                      />
                      <span className="text-red-3b text-xs">
                        {errors?.information?.message as string}
                      </span>
                    </div>
                  </div>
                  <div className="form-group">
                    <label
                      htmlFor="information_eng"
                      className="required-before"
                    >
                      Nội dung (EN)
                    </label>
                    <div className="w-full min-h-[300px]">
                      <EditorByJodit
                        editorValue={editorEngState}
                        saveEditorStateValues={onEditorEngStateChange}
                      />
                      <input
                        id="information_eng"
                        type="hidden"
                        autoComplete="information_eng"
                        className="form-control"
                        {...register('information_eng', {
                          required: 'Vui lòng nhập nội dung (EN)'
                        })}
                      />
                      <span className="text-red-3b text-xs">
                        {errors?.information_eng?.message as string}
                      </span>
                    </div>
                  </div>
                </ArticleCard>
              </div>

              <div className={`flex mt-2 gap-5`}>
                {paramsId ? (
                  <button
                    type="button"
                    className="form-button !min-w-[211px]"
                    onClick={() => {
                      setConfirmUpdateModal(true);
                    }}
                  >
                    Lưu
                  </button>
                ) : (
                  <button
                    type="button"
                    className="form-button !min-w-[211px]"
                    onClick={() => {
                      setConfirmAddModal(true);
                    }}
                  >
                    Thêm
                  </button>
                )}
              </div>
              <Modal
                show={confirmAddModal}
                title={'Xác Nhận Tạo Mới'}
                onClose={() => {
                  setConfirmAddModal(false);
                }}
                className="max-w-[420px]"
              >
                <div className="flex space-x-4">
                  <button
                    type="button"
                    className="flex justify-center w-full md:w-52 form-button-seconday"
                    onClick={() => {
                      setConfirmAddModal(false);
                    }}
                  >
                    Huỷ
                  </button>
                  <button
                    type="submit"
                    className="flex justify-center w-full md:w-52 form-button"
                    disabled={submitAdd}
                    onClick={handleSubmit(submitAddHandler, onError)}
                  >
                    {submitAdd && <CircleSpin />}
                    Đồng ý
                  </button>
                </div>
              </Modal>
              <Modal
                show={confirmUpdateModal}
                title={'Xác Nhận Chỉnh Sửa'}
                onClose={() => {
                  setConfirmUpdateModal(false);
                }}
                className="max-w-[420px]"
              >
                <div className="flex space-x-4">
                  <button
                    type="button"
                    className="flex justify-center w-full md:w-52 form-button-seconday"
                    onClick={() => {
                      setConfirmUpdateModal(false);
                    }}
                  >
                    Huỷ
                  </button>
                  <button
                    id="submitUpdate"
                    type="submit"
                    className="flex justify-center w-full md:w-52 form-button"
                    disabled={submitUpdate}
                    onClick={handleSubmit(submitUpdateHandler, onError)}
                  >
                    {submitUpdate && <CircleSpin />}
                    Đồng ý
                  </button>
                </div>
              </Modal>
            </form>
          </div>
        </>
      )}
    </>
  );
};

export default NewsAdd;
